<script>

import Swal from "sweetalert2";
import axios from "axios";
import Layout from "@/views/layouts/main.vue";

/**
 * MarketPlace Payment Status Component
 */
export default {

  components: {
    Layout
  },

  methods: {
    async fetchPaymentStatus() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urlParams = Object.fromEntries(urlSearchParams.entries());
      if (!urlParams.status && !urlParams.session_id) {
        return
      }

      const status = urlParams.status.toLowerCase();
      const isSuccess = status === 'success'

      await this.changePaymentStatus(status, urlParams.session_id)
      await Swal.fire(isSuccess ? "Sukces!" : "Błąd!", isSuccess ? "Proces realizacji transakcji przebiegł pomyślnie!" : "Proces realizacji transakcji nie powiódł się!", isSuccess ? "success" : "error")
          .then(() => {
            this.$router.push('/dashboard/home').catch((error) => {
              if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                console.log(error)
              }
            })
      });
    },

    async changePaymentStatus(status, sessionId) {
      if (sessionId === "free") {
        return
      }

      try {
        await axios.get(`/stripe/${status}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "session_id": sessionId
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  },

  async created() {
    await this.fetchPaymentStatus()
  }

};
</script>

<template>
  <Layout>

  </Layout>
</template>